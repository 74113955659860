<template>
  <div class="layout default-layout">
    <template v-if="userPermissions === 1">
      <employer-header />
      <employer-mobile-menu v-if="showMobileMenu" />
    </template>

    <template v-if="userPermissions === 2">
      <agency-header />
      <agency-mobile-menu v-if="showMobileMenu" />
    </template>

    <div class="layout-content not-found-content">
      <router-view />
    </div>
  </div>
</template>

<script>
import AgencyHeader from '@/components/agency/AgencyHeader.vue';
import AgencyMobileMenu from '@/components/agency/AgencyMobileMenu.vue';
import EmployerHeader from '@/components/employer/EmployerHeader.vue';
import EmployerMobileMenu from '@/components/employer/EmployerMobileMenu.vue';

import { mapGetters } from 'vuex';

export default {
  name: 'NotFoundLayout',
  components: {
    AgencyHeader,
    AgencyMobileMenu,
    EmployerHeader,
    EmployerMobileMenu,
  },
  computed: {
    ...mapGetters({
      userPermissions: 'auth/getAuthUserPermissions',
      showMobileMenu: 'getMobileMenuStatus',
    }),
  },
};
</script>

<style lang="scss" scoped>
.not-found-content {
  display: flex;
}
</style>
